import {apiClient} from "../utils/helpers";

export const links = {

    getHeaders () {
        const token = localStorage.getItem("token")
        return {
            headers: {
                token
            }
        }
    },

    async getAllLinks () {
        return await apiClient.get(`/links/getAllLinks`, this.getHeaders())
    },
    async setPaid (data) {
        return await apiClient.post(`/links/setPaid`, data, this.getHeaders())
    },

    async getUserLinks (data) {
        return await apiClient.get(`/links/getUserLinks`, this.getHeaders())
    },

    async createLinks (data) {
        return await apiClient.post(`/links/create`, data,  this.getHeaders())
    },

    async updateLinks (data) {
        return await apiClient.put(`/links/update`, data,  this.getHeaders())
    },

    async deleteLinks (data) {
        return await apiClient.delete(`/links/deleteUserLink`, this.getHeaders())
    },

}