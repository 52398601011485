import styled from "styled-components"

const ButtonStyled = styled('button')`
      background-color: var(--primary);
      border: 1px solid var(--primary);
      padding: 12px 40px;
      height: 45px;
      border-radius: ${({theme}) => theme.borderRadius.sm};
      color: var(--white);
      font-weight: 700;
      cursor: pointer;
      transition: .2s;
      font-size: ${({theme}) => theme.fontSize.sm};
      position: relative;

      &:hover {
        background-color: var(--white);
        color: var(--primary);
        
        .button-loader {
            span {
              background-color: var(--primary);
            }
        }
      }
  
      &:disabled {
        background-color: var(--disabled);
        border-color: var(--disabled);
        color: var(--white);
      }
      
      .button-loader {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          display: block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: var(--white);
          animation: buttonLoading 1s infinite;
          animation-direction: alternate;
         
         @keyframes buttonLoading {
            from {
              transform: scale(0);
            }
            to {
              transform: scale(1);
            }
         }
         
         &:nth-of-type(1) {
            animation-delay: 0s;
         }
         
         &:nth-of-type(2) {
            animation-delay: 0.3s;
            margin: 0 5px;
         }
         
         &:nth-of-type(3) {
            animation-delay: 0.6s;
         }
        }
      }
    `

function Button ({title, loading = false, onClick = () => {}, ...props}) {

    const handleClick = () => {
        if (loading) return;
        onClick()
    }

    return (
        <ButtonStyled onClick={handleClick} {...props}>
            {
                loading ? (
                    <div className={"button-loader"}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                ) : (
                    title
                )
            }
        </ButtonStyled>
    )
}

export default Button