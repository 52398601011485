import axios from "axios";

export const getAvatarCharacters = (name) => {
    if (!name) return name;
    const nameArr = name.split(" ")
    return nameArr[0].slice(0, 1).toUpperCase() + nameArr[1].slice(0, 1).toUpperCase()
}

export const isValidUrl = (link) => {
    const regex = /((((http|https):(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+-~%\/.\w_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/g
    return regex.test(link);
}

export const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API, 
    headers: {
        'Content-Type': 'application/json',
    }
})

export const getProductsCount = (allLinks) => {
    const productsData = {};
    allLinks.forEach(({links}) => {
        links.forEach(({title, link, count = 1}) => {
            if(title) {
                if(productsData[title]) {
                    productsData[title].count = productsData[title].count + count
                } else {
                    productsData[title] = {
                        count: count,
                        url: link
                    }
                }
            }
        })
    })
    return productsData;
}