import styled from "styled-components";
import {AnimatePresence, motion} from "framer-motion";
import loadingIcon from "../../utils/icons/loading.svg"

const LoadingStyled = styled(motion.div)`
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: var(--white); 
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`

const LoadingIcon = styled(motion.img)`
  width: 100px;
  height: 100px;
`

const loadingAnimationVariant = {
    open: {
        opacity: 1
    },
    close: {
        opacity: 0,
        pointerEvents: "none",
        transitionEnd: {
            display: "none",
        },
    }
}

const loadingIconAnimationVariant = {
    open: {
        scale: 1.5,
        transition: { repeat: Infinity, duration: 0.5, repeatType: "reverse" }
    },
    close: {
        scale: 1,
        transition: { repeat: 0, duration: 0.5, repeatType: "reverse" }
    }
}

export function Loading({loading}) {
    return <AnimatePresence
        initial={true}
        exitBeforeEnter={true}
        onExitComplete={() => null}
    >
        {
            <LoadingStyled
                animate={loading ? "open" : "close"}
                variants={loadingAnimationVariant}
                transition={{duration: 0.2}}
            >
                <LoadingIcon
                    src={loadingIcon}
                    animate={loading ? "open" : "close"}
                    variants={loadingIconAnimationVariant}
                />
            </LoadingStyled>
        }
    </AnimatePresence>
}