import styled from "styled-components";
import {Form, Button, Input, AuthContent} from "../components";
import * as yup from "yup"
import {useAuthContext} from "../context";

const FormStyled = styled(Form)`
    .input {
      &:not(:last-of-type) {
        margin-bottom: 20px;
      }
    }
`

const schema = yup.object().shape({
    username: yup.string().trim("Do not use spaces in the first and last characters").strict(true).required("This field is required"),
    password: yup.string().trim("Do not use spaces in the first and last characters").strict(true).required("This field is required"),
})

function SignUp() {

    const { dispatch } = useAuthContext()

    const handleSubmit = (data) => {
        dispatch.onSubmit(data, "login")
    }

    return (
        <AuthContent
            title={"Sign In"}
            helperText={`Need an account?`}
            path={"/signup"}
            link={"Sign Up"}
        >
            <FormStyled
                onSubmit={handleSubmit}
                validationSchema={schema}
            >
                <Input placeholder={"Username"} name={"username"} className={"input"}/>
                <Input type={"password"} placeholder={"Password"} name={"password"} className={"input"}/>

                <Button title={"Sign In"} />
            </FormStyled>

        </AuthContent>
    )
}

export default SignUp