import styled from "styled-components";
import * as yup from "yup"
import { Form, Button, Input, AuthContent } from "../components";
import {useAuthContext} from "../context";

const FormStyled = styled(Form)`
    .input {
      &:not(:last-of-type) {
        margin-bottom: 20px;
      }
    }
`

const schema = yup.object().shape({
    "firstName": yup.string().trim("Do not use spaces in the first and last characters").strict(true).required("This field is required"),
    "lastName": yup.string().trim("Do not use spaces in the first and last characters").strict(true).required("This field is required"),
    "username": yup.string().trim("Do not use spaces in the first and last characters").strict(true).required("This field is required"),
    "password": yup.string().trim("Do not use spaces in the first and last characters").strict(true).required("This field is required").min(6),
})

function SignUp() {

    const { dispatch } = useAuthContext()

    const handleSubmit = (data) => {
        dispatch.onSubmit(data, "signup")
    }

    return (
        <AuthContent
            title={"Sign Up"}
            helperText={`Already have an account?`}
            path={"/"}
            link={"Sign In"}
        >
            <FormStyled validationSchema={schema} onSubmit={handleSubmit}>
                <Input className={"input"} placeholder={"First Name"} name={"firstName"}/>
                <Input className={"input"} placeholder={"Last Name"} name={"lastName"}/>
                <Input className={"input"} placeholder={"Username"} name={"username"}/>
                <Input className={"input"} placeholder={"Card Number"} name={"cardNumber"}/>
                <Input type={"password"} className={"input"} placeholder={"Password"} name={"password"}/>

                <Button title={"Sign Up"} />
            </FormStyled>
        </AuthContent>
    )
}

export default SignUp