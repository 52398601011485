import {Button, Layout, Loading} from "../components";
import React, {useEffect, useMemo, useRef, useState} from "react";
import styled from "styled-components";
import HomeTable from "../components/pages/HomeTable";
import AddProductModal from "../components/pages/AddProductModal";
import {useHome} from "../hooks";
import {AppContextProvider} from "../context";
import {useRecoilState, useRecoilValue} from "recoil";
import {linksAtom, userAtom} from "../atoms";
import {ProductsCountBadge} from "../components/pages/ProductsCountBadge";

import {user as userApi} from "../api";
import {NotificationManager} from "react-notifications";

const HostTitle = styled.h3`
  font-size: ${({theme}) => theme.fontSize.md};
  text-align: center;
  margin: 45px 0 60px;
  
  span {
    color: var(--primary);
  }
`

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;
`

const CardNumber = styled.h5`
  font-size: 18px;
  font-weight: 700;
  
  span {
    color: var(--primary);
  }
`

const ProductsCountWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
  
  & > div {
    margin-bottom: 10px;
    margin-top: 10px;
    &:not(:last-of-type) {
      margin-right: 20px;
    }
  }
`

const InputStyled = styled.input`
  border-radius: ${({theme}) => theme.borderRadius.md};
  border: 1px solid ${({error}) => error ? "darkred" : "var(--primary)"};
  padding: 14px 20px;
  box-sizing: border-box;
  outline: none;
  color: var(--textColor);

  &::placeholder {
    font-size: 14px;
    color: ${({error}) => error ? "#c64646" : "var(--placeholder)"};
    font-weight: 500;
  }
`

const AddCardNumberContainer = styled.div`
  display: flex;
  
  input {
    margin-right: 20px;
  }
`

const ErrorMessage = styled.p`
  color: darkred;
  margin: 5px 20px;
  font-size: 14px;
  text-align: left;
`


export default function Home () {

    const { record, dispatch, loading } = useHome()

    const { userLinks, productsData } = useRecoilValue(linksAtom);
    const [userData, setUserData] = useRecoilState(userAtom);

    const [card, setCard] = useState();
    const [loadingAddCardNumber, setLoadingAddCardNumber] = useState(false);

    const [cardNumberError, setCardNumberError] = useState(false);
    const inputCard = useRef();

    const handleChange = () => {
        const cardValue = inputCard.current.value
            .replace(/\D/g, '')
            .match(/(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})/);
        inputCard.current.value = !cardValue[2]
            ? cardValue[1]
            : `${cardValue[1]} ${cardValue[2]}${`${
                cardValue[3] ? ` ${cardValue[3]}` : ''
            }`}${`${cardValue[4] ? ` ${cardValue[4]}` : ''}`}`;
        const numbers = inputCard.current.value.replace(/(\D)/g, '');

        setCard(numbers);
    };

    useEffect(() => {
        handleChange();
    }, [card]);

    const handleSubmitCardNumber = async () => {
        if (card.length < 16) {
            setCardNumberError(true)
        } else {
            setCardNumberError(false)
            setLoadingAddCardNumber(true)

            try {
                const updatedUser = await userApi.addCardNumber(card)

                setUserData({
                    ...userData,
                    user: {
                        ...userData.user,
                        cardNumber: updatedUser.data.user.cardNumber
                    }
                })
            } catch (e) {
                NotificationManager.error(e.response.data.message, "Error" )
            } finally {
                setLoadingAddCardNumber(false)
            }
        }
    }

    const [open, setOpen] = useState(false);

    const _renderHostTitle = useMemo(() => (
        <HostTitle>
            {record.isHostTaken && record.hostName ? <>Today host is <span>{record.hostName}</span></> :
                record.isHostTaken && !record.hostName ? `Today not host`:
                    `The host will be chosen at 11:15`
            }
        </HostTitle>
    ), [record.hostName, record.isHostTaken])

    const formatCardNumber = (cardNumber) => {
        return cardNumber?.replace(/(\d{4})(?=\d)/g, "$1 ");
    }

    const _renderCardNumberAndAddProducts = useMemo(() => {
        const isUserHaveLinks = userLinks.links.every(({link}) => link);
        const buttonName = isUserHaveLinks ? "Edit" : "Add";
        const isHostChecked = record.isHostTaken;
        return (
            <Grid>
                {
                    userData?.user?.cardNumber || record.cardNumber ? (
                        // record.isHostTaken && <CardNumber>Card Number: <span>{record?.cardNumber ? formatCardNumber(record?.cardNumber) : "Host please add your card number !!!"}</span> </CardNumber>
                        record.isHostTaken && <CardNumber>Card Number: <span>4083 0600 1370 7852</span> </CardNumber>
                    ) : (
                        <AddCardNumberContainer>
                            <div>
                                <InputStyled error={cardNumberError} type={"text"} ref={inputCard} onChange={handleChange}/>
                                {cardNumberError && <ErrorMessage>Incorrect Card Number</ErrorMessage>}
                            </div>

                            <Button loading={loadingAddCardNumber} onClick={handleSubmitCardNumber} title={"Add Card Number"} />
                        </AddCardNumberContainer>
                    )
                }
                
                <Button disabled={isHostChecked && !isUserHaveLinks} title={`${buttonName} Products`} onClick={() => setOpen(true)}/>
            </Grid>
        )
    }, [userLinks, record.hostName, record.isHostTaken, record.cardNumber, userData?.cardNumber, cardNumberError, handleSubmitCardNumber])

    const _renderHomeTable = useMemo(() => (
        <HomeTable/>
    ), [])

    const _renderLoading = useMemo(() => (
        <Loading loading={loading}/>
    ), [loading])

    const _renderProductsModal = useMemo(() => (
        <AddProductModal
            open={open}
            onClose={() => setOpen(false)}
        />
    ), [open])
    const _renderProductsCount = useMemo(() => (
        <ProductsCountWrapper>
            {
                Object.entries(productsData).map(([key, value]) => {
                    return (
                        <ProductsCountBadge key={key} title={key} count={value.count} url={value.url}/>
                    )
                })
            }
        </ProductsCountWrapper>
    ), [productsData])

    return (
        <AppContextProvider value={{...record, dispatch}}>
            {_renderLoading}
            <Layout>
                {_renderProductsModal}

                <div className={"container"}>
                    {_renderHostTitle}
                    {_renderCardNumberAndAddProducts}
                    {_renderProductsCount}
                    {_renderHomeTable}
                </div>
            </Layout>
        </AppContextProvider>
    )
}
