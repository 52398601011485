import styled from "styled-components";

const ProductsCountBadgeContainer = styled.a`
  padding: 10px 20px;
  border-radius: 100px;
  background-color: var(--white);
  box-shadow: 0 0 10px rgba(34, 34, 34, .25);
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--textColor);
  margin: 10px 20px 10px 0;
`

const ProductsCountBadgeTitle = styled.h3`
  margin: 0;
  font-size: 16px;
`

const ProductsCountBadgeCount = styled.div`
  min-width: 25px;
  height: 25px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--danger);
  border-radius: 50%;
  margin-left: 10px;
  
  font-weight: bold;
  color: var(--white);
`


export function ProductsCountBadge({title, count, url}) {
    return (
        <ProductsCountBadgeContainer href={url} target={"_blank"}>
            <ProductsCountBadgeTitle>
                {title}
            </ProductsCountBadgeTitle>
            <ProductsCountBadgeCount>
                {count}
            </ProductsCountBadgeCount>
        </ProductsCountBadgeContainer>
    )
}