import React, {createElement} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

export default function Form ({defaultValues, children, onSubmit, validationSchema, className}) {

    const methods = useForm({defaultValues, resolver: validationSchema && yupResolver(validationSchema)})
    const { handleSubmit, control, formState: {errors} } = methods;

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={className}>
            {React.Children.map(children, child => {
                return child.props.name ? (
                    createElement(child.type, {
                        ...{
                            ...child.props,
                            error: errors[child.props.name]?.message,
                            register: methods.register,
                            key: child.props.name,
                        }
                    })
                ) : createElement(child.type, {
                  ...{
                      ...child.props,
                      control,
                      register: methods.register,
                      errors,
                  }
                })
            })}
        </form>
    )
}