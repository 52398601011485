import styled from "styled-components";
import { Outlet } from "react-router-dom"

const AuthContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, var(--blue), var(--lightBlue));
  height: 100vh;
`

export function Auth() {
    return (
        <AuthContainer>
            <Outlet/>
        </AuthContainer>
    )
}