import {Children, createElement} from "react";
import styled from "styled-components"

const TableItem = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 100px 120px;
    align-items: center;
    background-color: var(--white);
    border-radius: ${({theme}) => theme.borderRadius.md};
    padding: 20px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .07);
    margin-bottom: 10px;
`

const TableHeadTitle = styled.h3`
  font-weight: 500;
  padding: 0 15px;
  text-align: ${({align}) => align || "left" };
  margin: 0;
`


const TableContainer = styled.div``

export const TableCell = ({data, renderComponent}) => {
    return (
        <>
            {
                renderComponent ? (
                    renderComponent(data)
                ) : (
                    data
                )
            }
        </>
    )
}


export default function Table ({
    tableHead = [],
    data = [],
    children
}) {

    return (
        <TableContainer>
            <TableItem>
                {
                    tableHead.map(({title, align}, i) => (
                        <TableHeadTitle align={align} key={i}>{title}</TableHeadTitle>
                    ))
                }
            </TableItem>

            {
                 data.length ? data.map((tableItemData, i) => (
                    <TableItem key={i}>
                        {
                            Children.map(children, (child) => {
                                return (
                                    createElement(child.type, {
                                        ...{
                                            ...child.props,
                                           data: tableItemData
                                        }
                                    })
                                )
                            })
                        }
                    </TableItem>
                )) : <TableItem>
                     <h3>No Data</h3>
                 </TableItem>
            }
        </TableContainer>
    )
}