import {Routes, Route, Navigate} from "react-router-dom"
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Home from "./pages/Home";
import {useEffect} from "react";
import {AuthProvider} from "./context";
import {useAuth} from "./hooks";
import {Auth} from "./pages/Auth";
import { NotificationContainer } from "react-notifications";
import 'react-notifications/lib/notifications.css';


function App() {

    const {userData, dispatch} = useAuth()

    useEffect(() => {
        const token = localStorage.getItem("token")
        dispatch.onChange({
            token
        })
    }, [])


  return (
      <AuthProvider value={{userData, dispatch}}>
         <Routes>
             {
                 userData.token ? (
                     <>
                         <Route path="/" element={<Home />} />
                     </>
                 ) : (
                     <Route path="/" element={<Auth />} >
                         <Route index element={<SignIn />} />
                         <Route path="signup" element={<SignUp />} />
                     </Route>
                 )
             }

             <Route
                 path="*"
                 element={<Navigate to="/" />}
             />

         </Routes>
          <NotificationContainer/>
      </AuthProvider>
  );
}

export default App;
