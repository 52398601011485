import {useFieldArray} from "react-hook-form";
import styled from "styled-components";
import Input from "./Input";
import deleteIcon  from "../../utils/icons/delete.svg"

const AddButton = styled.div`
  border-radius: ${({theme}) => theme.borderRadius.md};
  background-color: #4caf50;
  display: inline-block;
  padding: 10px 20px;
  color: var(--white);
  cursor: pointer;
  transition: .2s;
  
  &:hover {
    background-color: #39873d;
  }
`

const DynamicFormList = styled.ul`
  list-style: none;
  padding: 0;
  width: 100%;
`

const DynamicFormListItem = styled.li`
  margin-bottom: 30px;
  display: flex;
  align-items: center;
`

const DynamicFormFieldsContainer = styled.div`
  width: 100%;
  
  &:not(:first-of-type) {
     margin-right: 20px;
  }
  
  
  .dynamic-input:not(:last-of-type) {
    margin-bottom: 10px;
  }
`

const DeleteIcon = styled.img`
  cursor: pointer;
  margin-left: 20px;
`

const LinksAndCounter = styled.div`
  display: grid;
  grid-template-columns: 1fr 100px;
  grid-gap: 10px;
`

export default function DynamicForm ({control, register, errors, inputName}) {

    const { fields, append, remove } = useFieldArray({
        control,
        name: inputName,
    });

    return (
        <>
            <DynamicFormList>
            {
                fields.map((item, index) => (
                    <DynamicFormListItem key={`dynamic-form-${index}`}>
                        <DynamicFormFieldsContainer isOneElement={fields.length === 1}>
                            <LinksAndCounter>
                                <Input
                                    placeholder={"Enter Your link"}
                                    name={`${inputName}.${index}.link`}
                                    register={register}
                                    error={errors?.[inputName]?.[index]?.link?.message}
                                    className={"dynamic-input"}
                                />
                                <Input
                                    type={"number"}
                                    placeholder={"Count"}
                                    name={`${inputName}.${index}.count`}
                                    register={register}
                                    error={errors?.[inputName]?.[index]?.count?.message}
                                    className={"dynamic-input"}
                                />
                            </LinksAndCounter>
                            <Input
                                type={"number"}
                                placeholder={"Enter Your link product price"}
                                name={`${inputName}.${index}.price`}
                                register={register}
                                error={errors?.[inputName]?.[index]?.price?.message}
                                className={"dynamic-input"}
                            />
                        </DynamicFormFieldsContainer>
                        {
                            index > 0 && <DeleteIcon src={deleteIcon} onClick={() => remove(index)} />
                        }
                    </DynamicFormListItem>
                ))
            }
            </DynamicFormList>


            <AddButton title={"Add Product"} onClick={() => append({link: "", price: "", count: 1})}>
                + Add Product
            </AddButton>
        </>
    )
}