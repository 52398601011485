import {TableCell} from "../Table";
import {Form, Input} from "../form";
import {Table} from "../index";
import React from "react";
import styled from "styled-components";
import minus from "../../utils/icons/minus.svg"
import plus from "../../utils/icons/plus.svg"
import {useAppContext} from "../../context";
import {useRecoilValue} from "recoil";
import {linksAtom, userAtom} from "../../atoms";
import {isValidUrl} from "../../utils/helpers";
import emptyImage from "../../utils/empty.jpg";
const UserName = styled.p`
  padding: 0 15px;
  margin: 0;
`

const Comment = styled.p`
  padding: 0 15px;
  margin: 0;
`

const ProductsLinkContainer = styled.div`
  display: inline-grid;
  flex-direction: column;
  
  & {
      a, p {
      margin: 5px 0;
      }
  }
`

const ProductsLink = styled.a`
  font-weight: 600;
  padding: 0 15px;
  margin: 0;
  color: var(--lightBlue);
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const ProductsTitle = styled.p`
  font-weight: 600;
  padding: 0 15px;
  margin: 0;
  color: var(--textColor);
  text-decoration: none;

  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
`

const ProductsCount = styled.div`
  min-width: 40px;
  border-radius: 50%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--danger);
  color: var(--white);
  font-weight: bold;
`

const Price = styled.p`
  padding: 0 15px;
  margin: 0;
  font-weight: 500;
  text-align: center;
`

const Icon = styled.img`
  margin: 0 auto;
`

const ProductContainer = styled.div`
  padding: 10px;
  border-radius: 5px;
  background-color: #f6f6f6;
  
  display: flex;
  align-items: center;
`

const ProductImage = styled.div`
  min-width: 60px;
  height: 60px;
  border-radius: 5px;
  overflow: hidden;
  position:relative;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
  }
`


const tableHead = [
    {
        title: "User",
        align: "left"
    },
    {
        title: "Products",
        align: "left"
    },
    {
        title: "Comment",
        align: "left"
    },
    {
        title: "Price",
        align: "center"
    },
    {
        title: "Is it paid?",
        align: "center"
    }
]

export default function HomeTable () {

    const { dispatch } = useAppContext()
    const { user } = useRecoilValue(userAtom)
    const { allLinks } = useRecoilValue(linksAtom)

    const handleIsPaid = (e) => {
        dispatch.onPaid(e.target.checked)
    }

    return (
        <Table
            tableHead={tableHead}
            data={allLinks}
        >

            <TableCell renderComponent={({creatorName}) => (
                <UserName>{creatorName}</UserName>
            )}/>

            <TableCell renderComponent={({links}) => (
                <ProductsLinkContainer>
                    {
                        links.map(({link, imageURL, title, count}, index) => {
                            const isValid = isValidUrl(link)
                            const Component = isValid ? ProductsLink : ProductsTitle;
                            return (
                                <Component href={isValid && link} target={"_blank"} key={index}>

                                    { title && isValid ?  (
                                        <ProductContainer>
                                            <ProductImage>
                                                <img src={imageURL || emptyImage} alt="product image"/>
                                            </ProductImage>
                                            <ProductsTitle>
                                                {title}
                                            </ProductsTitle>
                                            <ProductsCount>
                                                {count}
                                            </ProductsCount>
                                        </ProductContainer>
                                    ) : link}

                                </Component>
                            )
                        })
                    }
                </ProductsLinkContainer>
            )}/>

            <TableCell renderComponent={({comment}) => (
                <Comment>{comment ? comment : "-"}</Comment>
            )}/>

            <TableCell renderComponent={({price}) => (
                <Price>{price.reduce((acc, val) => acc + +val, 0) + 100}</Price>
            )}/>

            <TableCell renderComponent={({isPaid, creatorId}) => (
                creatorId === user?._id ? (
                    <Form defaultValues={{isPaid}}>
                        <Input type="checkbox" name={"isPaid"} onChange={handleIsPaid} />
                    </Form>
                ) : (
                    <Icon src={isPaid ? plus : minus}/>
                )
            )}/>
        </Table>
    )
}