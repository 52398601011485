import {useCallback, useEffect, useMemo, useState} from "react";
import {useRecoilState} from "recoil"
import {user, links} from "../api";
import {userAtom, linksAtom} from "../atoms";
import {NotificationManager} from "react-notifications";
import {useAuthContext} from "../context";
import {getProductsCount} from "../utils/helpers";

export function useHome() {
    const { dispatch: dispatchAuth } = useAuthContext()
    const [userRecord, setUserRecord] = useRecoilState(userAtom);
    const [_, setLinksRecord] = useRecoilState(linksAtom);
    const [ loading, setLoading ] = useState(true)
    const [record, setRecord] = useState({
        hostName: "",
        isHostTaken: false
    });

    const dispatch = useMemo(() => ({}), [])

    const getHost = useCallback(async () => {
        try {
            const hostData = await user.getHost()
            dispatch.onChange({
                cardNumber: hostData.data.hostCardNumber,
                hostName: hostData.data.hostName,
                isHostTaken: hostData.data.isHostTaken,
            })
        } catch (e) {
            NotificationManager.error(e.response.data.message, "Error" )
        }

    }, [dispatch])

    const getLinks = useCallback(async () => {
        try {
            const allLinksData = await links.getAllLinks()
            const userLinksData = await links.getUserLinks();

            const { allLinks } = allLinksData.data;
            const { links: linksData, comment } = userLinksData.data;

            const userLinks = !linksData.length ? {
                links: [{
                    link: "",
                    price: "",
                    count: 1
                }],
                comment: ""
            } : {
                links: linksData,
                comment
            }

            const productsData = getProductsCount(allLinks);

            setLinksRecord({
                allLinks,
                userLinks,
                productsData
            })
        } catch (e) {
            NotificationManager.error(e.response.data.message, "Error" )
        }

    }, [setLinksRecord])

    const getUserData = useCallback(async () => {
        try {
            const {data: {user: userData}} = await user.getUser()
            setUserRecord({
                ...userRecord,
                user: userData
            })
        } catch (e) {
            setLoading(false)
            dispatchAuth.logout();
            NotificationManager.error(e.response.data.message, "Error" )
        }
    }, [setUserRecord, userRecord])


    useEffect(() => {
        (async () => {
            await getHost();
            await getLinks();
            await getUserData();
            setLoading(false)
        })()

    }, [])

    dispatch.onChange = useCallback((data) => {
        setRecord(prevState => ({
            ...prevState,
            ...data
        }))
    }, [])

    dispatch.onPaid = useCallback(async (value) => {
        try {
            const res = await links.setPaid({isPaid: value})
            NotificationManager.success(res.data.message, "Success" )
        } catch (e) {
            NotificationManager.error(e.response.data.message, "Error" )
        }

    }, [])


    return {
        loading,
        record,
        dispatch
    }
}