export const theme = {
    fontFamily: "Roboto, sans-serif",

    fontSize: {
        lg: "36px",
        md: "24px",
        sm: "16px"
    },

    borderRadius: {
        lg: "20px",
        md: "15px",
        sm: "10px"
    }

}