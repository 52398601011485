import styled from "styled-components";
import {Link} from "react-router-dom";

const AuthContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, var(--blue), var(--lightBlue));
  height: 100vh;
`

const AuthContentStyled = styled.div`
  padding: 20px 40px 60px;
  border-radius: ${({theme}) => theme.borderRadius.lg};
  background-color: var(--white);
  max-width: 480px;
  width: 100%;
  text-align: center;
  box-shadow: 0 1px 2px rgba(34, 34, 34, .25);
  
  & {
      input:not(:last-of-type) {
        margin-bottom: 20px;
      }
      
      button {
        max-width: 200px;
        width: 100%;
        margin: 40px auto 20px;
      }
  } 
 
  & > p {
    font-size: 14px;
    font-weight: 500;
    
    & > a {
      color: var(--primary);
      text-decoration: none;
    }
  }
`

const Title = styled.h3`
  font-size: ${({theme}) => theme.fontSize.md};
  color: var(--primary);
  text-align: center;
`

function AuthContent({
    title,
    helperText,
    link,
    path,
    children
}) {
    return (
        <AuthContentStyled>
            <Title>{title}</Title>

            {children}

            <p>{helperText} <Link to={path}>{link}</Link></p>
        </AuthContentStyled>
    )
}

export default AuthContent