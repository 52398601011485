import {apiClient} from "../utils/helpers";

export const user = {
    getHeaders () {
        const token = localStorage.getItem("token")
        return {
            headers: {
                token
            }
        }
    },

    async getUser () {
        return await apiClient.get(`/user/`, this.getHeaders())
    },
    async getHost () {
        return await apiClient.get(`/user/getHost`, this.getHeaders())
    },
    async addCardNumber (cardNumber) {
        return await apiClient.post(`/user/addCardNumber`, {
            cardNumber
        }, this.getHeaders())
    }
}