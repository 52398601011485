import Header from "./Header";
import styled from "styled-components"

const LayoutContainer = styled.div`
  background-color: #f6f6f6;
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 60px;
  box-sizing: border-box;
`

function Layout({children}) {
    return (
        <LayoutContainer>
            <Header/>

            { children }

        </LayoutContainer>
    )
}

export default Layout