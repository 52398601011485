import styled from "styled-components";

const InputContainer = styled.div``

const InputStyled = styled.input`
    border-radius: ${({theme}) => theme.borderRadius.md};
    border: 1px solid ${({error}) => error ? "darkred" : "var(--primary)"};
    padding: 14px 20px;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    color: var(--textColor);
  
    &::placeholder {
        font-size: 14px;
        color: ${({error}) => error ? "#c64646" : "var(--placeholder)"};
        font-weight: 500;
    }
`

const TextAreaStyled = styled(InputContainer)`

    border-radius: ${({theme}) => theme.borderRadius.md};
    border: 1px solid ${({error}) => error ? "darkred" : "var(--primary)"};
    padding: 14px 20px;
    width: 100%;
    height: 200px;
    resize: none;
    box-sizing: border-box;
    outline: none;
    color: var(--textColor);
  
    &::placeholder {
        font-size: 14px;
        color: ${({error}) => error ? "#c64646" : "var(--placeholder)"};
        font-weight: 500;
    }
`

const ErrorMessage = styled.p`
  color: darkred;
  margin: 5px 20px;
  font-size: 14px;
  text-align: left;
`

function Input ({register, name, onChange, error, className, mode,  ...rest}) {
    return (
        <InputContainer className={className}>
            {
                mode === "textarea" ? (
                    <TextAreaStyled as={"textarea"} error={error} {...register(name, {
                        onChange
                    })} {...rest}/>
                ) : (
                    <InputStyled error={error} {...register(name, {
                        onChange
                    })} {...rest} />
                )
            }

            {error && <ErrorMessage>{error}</ErrorMessage>}
        </InputContainer>
    )
}

export default Input