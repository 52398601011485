import {createGlobalStyle} from "styled-components";

import robotoBold from "../utils/fonts/Roboto-Bold.ttf"
import robotoMedium from "../utils/fonts/Roboto-Medium.ttf"
import robotoRegular from "../utils/fonts/Roboto-Regular.ttf"
import robotoLight from "../utils/fonts/Roboto-Light.ttf"

const GlobalStyle = createGlobalStyle`
  
  body {
    margin: 0;
    font-family: ${props => props.theme.fontFamily};
    font-size: ${props => props.theme.fontSize.sm};
    color: var(--textColor);
    overflow: hidden;

    --blue: #0089C9;
    --lightBlue: #00AEF0;
    --primary: #0089C9;
    --secondary: #00AEF0;
    --white: #fff;
    --placeholder: #8B8B8B;
    --textColor: #444;
    --danger: #c33a3a;
    --disabled: #d3d3d3;



    @font-face {
      font-family: 'Roboto';
      src: url(${robotoBold});
      font-weight: 700;
      font-style: normal;
    }

    @font-face {
      font-family: 'Roboto';
      src: url(${robotoMedium});
      font-weight: 500;
      font-style: normal;
    }

    @font-face {
      font-family: 'Roboto';
      src: url(${robotoRegular});
      font-weight: 400;
      font-style: normal;
    }

    @font-face {
      font-family: 'Roboto';
      src: url(${robotoLight});
      font-weight: 300;
      font-style: normal;
    }
    
    
    .container {
      margin: 0 auto;
      max-width: 1200px;
      width: 100%;
      padding: 0 20px;
    }
    
    .notification {
      border-radius: ${({theme}) => theme.borderRadius.sm};
    }
  }
`

export default GlobalStyle