import {useRecoilState} from "recoil";
import {userAtom} from "../atoms";
import {auth} from "../api";
import {useCallback} from "react";
import { NotificationManager } from "react-notifications";

export function useAuth() {
    const [userData, setUserData] = useRecoilState(userAtom);

    const dispatch = {}

    dispatch.onSubmit = async (data, method) => {
        try {
            const authData = await auth[method](data);
            const token = authData?.data?.token;

            if(token){
                localStorage.setItem("token", token);
                dispatch.onChange({token})
            }
        } catch (e) {
            NotificationManager.error(e?.response?.data?.message, "Error" )
        }
    }

    dispatch.logout = useCallback(() => {
        localStorage.removeItem("token")

        dispatch.onChange({token: ""})
    }, [dispatch])

    dispatch.onChange = useCallback((newState) => {
        setUserData({
            ...userData,
            ...newState
        })
    }, [setUserData, userData])

    return {userData, dispatch}
}