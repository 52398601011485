import styled from "styled-components"
import close from "../utils/icons/close.svg"
import {AnimatePresence, motion} from "framer-motion";

const ModalContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: rgba(34, 34, 34, .4);
  overflow-x: hidden;
`

const ModalWrapper = styled.div`
  max-width: 680px;
  width: 100%;
  background-color: var(--white);
  z-index: 10;
  border-radius: ${({theme}) => theme.borderRadius.lg};
  position: relative;
`


const ModalHeader = styled.div`
  padding: 20px 40px;
  border-bottom: 1px solid #f6f6f6;
  font-size: ${({theme}) => theme.fontSize.md};
  font-weight: 700;
  color: var(--primary);
`

const ModalBody = styled.div`
  padding: 40px;
`

const CloseButton = styled.img`
  position: absolute;
  right: 40px;
  top: 26px;
  cursor: pointer;
`

const ModalDialog = styled(motion.div)`
  padding: 1.75rem 0;
  display: flex;
  justify-content: center;
  width: auto;
  align-items: flex-start;
`

const containerVariant = {
    hidden: {
        scale: 0,
    },
    visible: {
        scale: 1,
        transition: {
            duration: 0.2,
            type: "spring",
            damping: 20,
            stiffness: 300,
        },
    },
    exit: {
        scale: 0
    }
}

export default function Modal({children, title, onSubmit, open, onClose }) {

    return (
        <AnimatePresence
            initial={false}
            exitBeforeEnter={true}
            onExitComplete={() => null}
        >
            {
                open &&
                <ModalContainer
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{duration: 0.2}}
                >
                    <ModalDialog
                        variants={containerVariant}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                    >
                        <ModalWrapper>
                            <ModalHeader>
                                {title}
                                <CloseButton src={close} onClick={onClose}/>
                            </ModalHeader>
                            <ModalBody>
                                {children}
                            </ModalBody>
                        </ModalWrapper>
                    </ModalDialog>
                </ModalContainer>

            }
        </AnimatePresence>
    )
}