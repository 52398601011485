import { useMemo} from "react";
import styled from "styled-components"
import {NavLink} from "react-router-dom";
import {useRecoilValue} from "recoil";
import exit from "../../utils/icons/exit.svg"
import {getAvatarCharacters} from "../../utils/helpers";
import {useAuthContext} from "../../context";
import {userAtom} from "../../atoms";

const HeaderContainer = styled.header`
  padding: 10px 0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .25);
  background-color: var(--white);
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const NameCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background-color: #DDEFF8;
  color: var(--primary);
  font-size: ${({theme}) => theme.fontSize.md};
  font-weight: 700;
  margin-right: 70px;
`

const Navigation = styled.nav`
  display: flex;
  align-items: center;
  
  & {
    a {
      font-size: 20px;
      font-weight: 600;
      text-decoration: none;
      color: var(--textColor);
      
      &.active {
        color: var(--primary);
      }
      
      &:first-of-type {
        margin-right: 50px;
      }
    }
  }
`

const AvatarGrid = styled.div`
  display: flex;
  align-items: center;
`

const ExitButton = styled.img`
  cursor: pointer;
`

export default function Header () {

    const { dispatch } = useAuthContext()
    const { user } = useRecoilValue(userAtom)

    const username = `${user?.firstName} ${user?.lastName}`
    const avatarTitle = useMemo(() => getAvatarCharacters(username), [username])

    return (
        <HeaderContainer >
            <HeaderWrapper className={"container"}>
                <Navigation>
                    <NavLink to={"/"}>Home</NavLink>
                    {/*<NavLink to={"/history"}>My History</NavLink>*/}
                </Navigation>

                <AvatarGrid>
                    <NameCircle>
                        {avatarTitle}
                    </NameCircle>
                    <ExitButton src={exit} onClick={dispatch.logout}/>
                </AvatarGrid>
            </HeaderWrapper>
        </HeaderContainer>
    )
}