import React, {useState} from "react";
import styled from "styled-components"
import { useRecoilState, useRecoilValue } from "recoil"
import {Button, Form} from "../form";
import DynamicForm from "../form/DynamicForm";
import Modal from "../Modal";
import * as yup from "yup"
import {linksAtom, userAtom} from "../../atoms";
import {links} from "../../api";
import Input from "../form/Input";
import {useAppContext} from "../../context";
import {getProductsCount} from "../../utils/helpers";

const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  .comment-area {
    width: 100%;
    margin-top: 40px;
  }
`

const FormButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px auto 20px;
  width: 100%;
  
  .submit-button {
      width: 140px;
  }
`

const DeleteLinkButton = styled.div`
  padding: 12px 40px;
  border-radius: ${({theme}) => theme.borderRadius.sm};
  border: 1px solid ${({disabled}) => disabled ? "var(--disabled)" : "var(--danger)"};
  background-color: ${({disabled}) => disabled ? "var(--disabled)" : "var(--danger)"};
  color: var(--white);
  cursor: ${({disabled}) => disabled ? "default" : "pointer"};
  font-weight: bold;
  font-size: 14px;
  transition: .2s;
  
  
  
  &:hover {
    background-color: ${({disabled}) => disabled ? "var(--disabled)" : "var(--white)"};
    color: ${({disabled}) => disabled ? "var(--white)" : "var(--danger)"};
  }
`

const schema = yup.object().shape({
    links: yup.array().of(
        yup.object().shape({
            link: yup.string().trim("Do not use spaces in the first and last characters").strict(true).required("This field is required"),
            price: yup.number("Price must be a number")
                .required("This field is required")
                .typeError('This field must contain only numbers')
                .min(0, "Price must be greater than or equal to 0")
                .max(100000, "Price must be less than or equal to 100000"),
            count: yup.number("Price must be a number")
                .required("This field is required")
                .typeError('This field must contain only numbers')
                .min(0, "Price must be greater than or equal to 0")
        })
    ),
});

export default function AddProductModal ({
    open,
    onClose
}) {
    const [loading, setLoading] = useState(false)
    const [ linksRecord, setLinksRecord ] = useRecoilState(linksAtom);
    const { user } = useRecoilValue(userAtom);
    const { isHostTaken } = useAppContext()

    const { userLinks, allLinks } = linksRecord
    const isUserHaveLinks = userLinks.links.every(({link}) => link);
    const isHostChecked = isHostTaken;
    const isCreate = !userLinks.links?.filter(({link}) => link).length;

    const handleSubmit = async (value) => {
        setLoading(true)
       const method = isCreate ? "createLinks" : "updateLinks"
       const newLinksData = await links[method](value);

       const { linksData } = newLinksData.data;
        let newAllLinks;

        if (isCreate) {
            newAllLinks = [...allLinks, linksData]
        } else {
            newAllLinks = allLinks.length ? allLinks.reduce((acc, link) => {
                if (link.creatorId !== user._id) {
                    return [...acc, link]
                } else {
                    return [...acc, linksData]
                }
            }, []): [linksData]
        }

        const productsData = getProductsCount(newAllLinks);

        setLinksRecord({
            productsData,
            userLinks: {
                links: linksData.links.map((linkData, index) => ({
                    ...linkData,
                    price: linksData.price[index]
                })),
                comment: value.comment
            },
            allLinks: newAllLinks
        })
        setLoading(false)
        onClose();
    }
    const handleDeleteLink = async () => {
        try {
            if (isCreate || (isHostChecked && isUserHaveLinks)) return;
            await links.deleteLinks();
            const newAllLinks = allLinks.filter(({creatorId}) => creatorId !== user._id);

            const productsData = getProductsCount(newAllLinks);
            setLinksRecord({
                productsData,
                userLinks: {links: [{link: "", price: ""}], comment: ""},
                allLinks: newAllLinks
            })
            onClose();
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Modal
            open={open}
            title={"Put your links here. Every link in a single input"}
            onClose={loading ? () => {} : onClose}
        >
            <FormStyled
                defaultValues={{links: [...userLinks.links.map(({link, price, count}) => ({link: link, price, count}) )], comment: userLinks.comment}}
                validationSchema={schema}
                onSubmit={loading ? () => {} : handleSubmit}
            >
                <DynamicForm inputName={"links"}/>

                <Input mode={"textarea"} name={"comment"} className={"comment-area"} placeholder={"Write comment if you need"}/>

                <FormButtonsContainer>
                    <DeleteLinkButton disabled={isCreate || (isHostChecked && isUserHaveLinks) || loading} onClick={handleDeleteLink}>
                        Delete my links
                    </DeleteLinkButton>
                    <Button
                        loading={loading}
                        title={isCreate ? "Create" : "Update"}
                        className={"submit-button"}
                    />
                </FormButtonsContainer>
            </FormStyled>
        </Modal>
    )
}