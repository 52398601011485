import {atom} from "recoil";

export const linksAtom = atom({
    key: 'links', // unique ID (with respect to other atoms/selectors)
    default: {
        productsData: {},
        allLinks: [],
        userLinks: {
            links: [],
            comment: ""
        },
    },
});